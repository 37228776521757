// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-tsx": () => import("/Users/nakami/workspace/tech-of-light/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-pages-404-js": () => import("/Users/nakami/workspace/tech-of-light/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/nakami/workspace/tech-of-light/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/nakami/workspace/tech-of-light/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-3-tsx": () => import("/Users/nakami/workspace/tech-of-light/src/pages/page-3.tsx" /* webpackChunkName: "component---src-pages-page-3-tsx" */)
}

